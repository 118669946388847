<template>
	<section class="gallery-section">
		<div class="row row-header">
			<div class="columns column12 align-center">
				<h2>{{ header }}</h2>
				<div v-parse-links v-html="content" />
			</div>
		</div>

		<div class="row">
			<div class="columns column12 gallery-row">
				<div v-for="item in items" :key="item.ID" class="gallery-item">
					<a
						v-if="item.imagePortrait"
						:data-src="item.imagePortrait"
						class="portrait"
						data-fancybox="gallery"
						:data-caption="item.imagePortraitAlt"
						:title="item.imagePortraitAlt"
					>
						<picture>
							<source v-if="item.imagePortraitWebp" :srcset="item.imagePortraitWebp" type="image/webp" />
							<source :srcset="item.imagePortrait" />
							<img :src="item.imagePortrait" :alt="item.imagePortraitAlt" loading="lazy" />
						</picture>
					</a>
					<a
						v-else-if="item.imageLandscape"
						:data-src="item.imageLandscape"
						class="landscape"
						data-fancybox="gallery"
						:data-caption="item.imageLandscapeAlt"
						:title="item.imageLandscapeAlt"
					>
						<picture>
							<source
								v-if="item.imageLandscapeWebp"
								:srcset="item.imageLandscapeWebp"
								type="image/webp"
							/>
							<source :srcset="item.imageLandscape" />
							<img :src="item.imageLandscape" :alt="item.imageLandscapeAlt" loading="lazy" />
						</picture>
					</a>
				</div>
			</div>
		</div>
		<div class="row button-row">
			<div class="columns column12 align-center">
				<nuxt-link class="button button-cta" :to="localePath('gallery')">
					{{ $t('buttonViewGallery') }}
				</nuxt-link>
			</div>
		</div>
	</section>
</template>

<script setup>
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

defineProps({
	header: { type: String, default: '' },
	content: { type: String, default: '' },
	items: { type: Array, default: () => [] },
});

onMounted(() => {
	Fancybox.bind('[data-fancybox]', {});
});
</script>

<style lang="scss" scoped>
.gallery-section {
	background: var(--light-background-color);

	h2 {
		color: var(--header-color-secondary);
	}
}

.gallery-row {
	padding: 20px;
	display: grid;
	grid-template: 50% 50% / 33.333% 33.333% 33.333%;
	justify-content: space-between;

	.gallery-item {
		width: 100%;
		display: inline-block;
		padding: 20px;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			position: absolute;
			inset: 0;
			object-fit: cover;
		}

		&:nth-child(2) {
			grid-area: 1 / 2 / 3 / 2;
			max-height: 100%;

			img {
				position: relative;
			}
		}
	}

	a {
		display: flex;
		flex-flow: row wrap;
		justify-content: stretch;
		align-items: stretch;
		height: 100%;

		&::after {
			display: none;
		}

		&.portrait {
			height: 100%;
			padding: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

.button-row {
	margin: 25px auto 0;
}

@media (max-width: 980px) {
	.gallery-row {
		padding: 5px;
		display: grid;
		grid-template: auto auto auto / 50% 50%;
		justify-content: space-between;

		.gallery-item {
			padding: 10px;
			min-height: 340px;
			overflow: hidden;

			&:nth-child(2) {
				display: flex;
				grid-area: 2 / 1 / 2 / 3;
			}
		}
	}
}

@media (max-width: 780px) {
	.gallery-row {
		.gallery-item {
			min-height: 280px;
		}
	}
}

@media (max-width: 620px) {
	.gallery-row {
		.gallery-item {
			min-height: 220px;
		}
	}
}

@media (max-width: 480px) {
	.gallery-row {
		.gallery-item {
			min-height: 180px;
		}
	}
}
</style>
