<template>
	<page-container
		class="index"
		:class="{
			'has-landscape-video': page.vimeoIDLandscape,
			'has-portrait-video': page.vimeoIDPortrait,
		}"
		:page="page"
	>
		<heading-image
			v-if="page.headingImage && !(page.vimeoIDLandscape && page.vimeoIDPortrait)"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>

		<video-header v-if="page.vimeoIDLandscape" :video="page.vimeoIDLandscape" type="landscape" />
		<video-header v-if="page.vimeoIDPortrait" :video="page.vimeoIDPortrait" type="portrait" />

		<main-content
			v-if="!page.introblock"
			:title="page.title || page.header"
			:content="page.content"
			:intro-image="{
				image: page.introductionImage,
				imageWebp: page.introductionImageWebp,
			}"
		/>

		<intro-block v-if="page.introblock" :item="page.introblock" />

		<menu-overview
			:items="menus"
			:image="defaults[locale].homepage.sectionMenusImage"
			:header="defaults[locale].homepage.sectionMenusTitle"
			:content="defaults[locale].homepage.sectionMenusContent"
		/>

		<gallery-overview
			:items="gallery.slice(0, 5)"
			:header="defaults[locale].homepage.sectionGalleryTitle"
			:content="defaults[locale].homepage.sectionGalleryContent"
		/>

		<contentblocks-slider :items="contentblocksData" />

		<usp-block
			:header="defaults[locale].homepage.sectionRestaurantfacilitiesTitle"
			:items="defaults[locale].homepage.sectionRestaurantfacilities"
			color="white"
		/>

		<contentblocks :items="page.contentblocks" />

		<usp-block v-if="page.uSPBlock" :header="page.uSPBlock.header" :items="page.uSPBlock.items" color="white" />

		<section class="opening-hours align-center">
			<div class="row row-header">
				<div class="columns column12">
					<h2>{{ defaults[locale].homepage.sectionOpeningHoursTitle }}</h2>
				</div>
				<div class="row">
					<div class="columns column12">
						<div v-parse-links v-html="defaults[locale].homepage.sectionOpeningHoursContent" />
					</div>
				</div>
			</div>
		</section>

		<magazine
			:key="locale"
			:items="magazineData.slice(0, 3)"
			:group-page="groupsPages.find((page) => page.slug == 'magazine-index')"
		/>

		<frequently-asked-questions v-if="page.faqs && page.faqs.length" :items="page.faqs" />
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { groupsPages, fetchGroupsPages } = await useGroupsPages();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchGroupsPages();
await fetchDefaults();

const { data: magazineData } = await useWebsiteFetch('magazine', {
	api: 'group',
	query: { language: locale.value },
	key: `${locale.value}/magazine`,
});

const { data: menus } = await useWebsiteFetch('menus', {
	query: { language: locale.value },
	key: `${locale.value}/menus`,
});

const { data: galleryCategories } = await useWebsiteFetch('gallery', {
	query: { language: locale.value },
	key: `${locale.value}/gallery`,
});

const gallery = galleryCategories.value.map((item) => item.items).flat();

const { data: contentblocksData } = await useWebsiteFetch('contentblocks', {
	query: { language: locale.value },
	key: `${locale.value}/contentblocks`,
});
</script>

<style lang="scss" scoped>
.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 25%);
}

.opening-hours {
	h2,
	h3 {
		color: var(--header-color-tertiary);
	}
}

.hero-content {
	position: absolute;
	max-width: 1400px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 50%;
	margin: 0 auto -70px;
	text-align: center;
	color: #fff;
	letter-spacing: 2px;

	:deep(span) {
		color: #fff;
		font-size: 4.4rem;
		font-family: var(--heading-font-family);
		font-weight: 700;
		margin: 0 0 10px;
		width: 100%;
		display: inline-block;
		line-height: 1;
	}

	:deep(p) {
		font-size: 1.8em;
	}
}

@media (max-width: 1080px) {
	.intro-image {
		display: none;
	}
}

@media (max-width: 880px) {
	.hero-content {
		:deep(span) {
			font-size: 3.4rem;
		}

		:deep(p) {
			font-size: 1.6em;
			padding: 0 10px;
		}
	}
}
</style>
